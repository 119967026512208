import { MOBILE_BREAKPOINT } from "marketingWebsite/utils/constants.js"

import { header, masthead } from "./elements.js"

/**
 * Handles sticky header behavior based on scroll position and window width
 * Adds scroll and resize event listeners to toggle sticky header class
 * Header becomes sticky when scrolled past masthead height on desktop
 * Sticky behavior is disabled on mobile (width <= MOBILE_BREAKPOINT)
 * @returns {void}
 */

export default function useStickyHeader () {
    function handleScroll () {
        const windowWidth = window.innerWidth

        if ( windowWidth <= MOBILE_BREAKPOINT ) {
            header.classList.remove( "sticky" )

            return
        }

        const isSticky = window.scrollY > masthead.offsetHeight
        header.classList.toggle( "sticky", isSticky )
    }

    window.addEventListener( "scroll", handleScroll )
    window.addEventListener( "resize", handleScroll )
}



